$darkgrey: #1f1f1f;
$mediumgrey: #5c5c5c;

.card__outercontainer {
    margin: 8px;
}
.card__container {
  width: calc(33% - 16px);
  background-color: white;
  padding: 12px;
  margin: 12px 8px;
  border-radius: 4px;
  box-shadow: 0px 1px 1px #a6a6a6;
  display: flex;
  flex-wrap: wrap; 
  @media(max-width: 767px){
      width: calc(50% - 16px);
  }
  @media(max-width: 500px){
    width: calc(100% - 16px);
  }
}

.card__section{
  width: 100%;
  
}
.card__featuredimage {
  width: 100%;
}
.card__section--middle {
  margin-bottom:6px;
  cursor: pointer;
}
.card__logo {
  position:relative;

  width: 80px;
  height: 80px;
  border-radius:40px;
  margin: -50px auto 0;
  display:block;
}
.card__title {
  font-weight: 400;
  padding-bottom: 6px;
  font-size: 21px;
  color: $darkgrey;
  flex-wrap: wrap;
}
.card__description {
  color: $mediumgrey;
  font-weight: 300;
  font-size: 12px;
  margin-bottom: 12px;
  flex-wrap: wrap;
  padding-right: 12px;
}
.card__iconcontainer {
  margin: 0 auto;
  text-align:center;
}
.card__button {
  text-transform: uppercase;
  margin: 0 10px;
  text-decoration:none;
  padding: 0px;
  height:24px;
    &:hover {
    path {
      fill: #f1b4bf;
    }
    .card__iconnumber {
      color: #f1b4bf;
    }
  }
}
.card__iconnumber {
  display: inline-block;
  position: relative;
  top: -6px;
  color: #d9d9d9;
  transition: .5s;
}
.card__icon {
  text-transform: uppercase;
  text-decoration:none;
  padding: 0px;
  height:24px;
  color: #d9d9d9;
  
  path {
    fill: #d9d9d9;
    transition:.5s;
  }

}
