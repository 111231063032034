.formsection {
    max-width: 600px;
    margin: 0 auto;
    &__header {
        font-family: $serif; 
        font-size: 2em;
        margin-bottom: 0px;
    }
    &__subheader {
        font-family: $sans-serif;
        font-size: 1.25em;
        font-weight: 300;
        margin-bottom: 12px;
    }
    &__form {
        display: block;
        width: 100%;
    }
    &__inputcontainer {
        position: relative;

        border-radius: 4px;
        margin-bottom: 14px;
    }
    &__input {
        border: none;
        font-size: 16px;
        width: 100%;
        height: 100%;
        padding: 12px;
        border: 2px solid $color-brand-dark-grey;
        &:focus {
            outline: none;
            border: 2px solid $color-brand-dark-pink;
        }
        &--submit {
            background: $color-brand-dark-pink;
            font-size: 16px;
            padding: 12px 32px;
            border-radius: 28px;
            border: none;
            color: white;
        }
    }
    &__label{
        position: absolute;
        top: 10px;
        left: 12px;
        font-size: 1em;
        font-weight: 300;
        transition: .5s;
        background: white;
        padding: 4px;
        &--active {
            top: -10px;
            font-size:.75em;
        }
    }
}