.blog-body {
    blockquote {
        border-left-color: rgba(0, 0, 0, 0.2);
        border-left: solid 4px;
        font-style: italic;
        margin: 1rem 0 1rem 0;
        padding: 0.5rem 0 0.5rem 2rem;
        quotes: none;
    }
    h2 { 
        font-size: 2.5rem;
        margin: 1rem 0 1rem 0;
        font-family: $serif;
        font-weight: 800;
    }
    h3 { 
        font-size: 1.5rem;
        margin: 1rem 0 1rem 0;
        font-family: $serif;
        font-weight: 600;
    }
}

.blog-header { 
    display: flex;
    justify-content: space-between; 
    margin-bottom: 16px;
    h2 { 
        font-size: 2.5rem;
        margin: 1rem 0 1rem 0;
        font-family: $serif;
        font-weight: 800;
    }
    h3 { 
        font-size: 1.5rem;
        margin: 1rem 0 1rem 0;
        font-family: $serif;
        font-weight: 600;
    }  
    @media screen and (max-width: 1024px){
        flex-direction: column;
        align-items: stretch;
        width: 100%;
    }
}
.blog__meta {
    width: 270px;
    height: 60px;
    background: $color-brand-dark-pink;
    padding: 8px 16px; 
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    @media screen and (max-width: 1024px){
        flex-direction: column;
        align-items: stretch;
        width: 100%; 
        justify-content: center;

    }

    * {
        background: $color-brand-dark-pink;
        color: white;
        font-family: $serif;  
    }
    .hero__image {
        width: 50px;
        height: 50px;  
        border-radius: 4px;
    }
    .blog__author {
        width: calc(100% - 100px);
        text-align: right;
    }

    .blog__pin {
        width: 80px;
        text-align: left;
    }

}
.slick-slider {
    max-width: 600px;
    width: 90%;
    margin: 32px auto; 
}