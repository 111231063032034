.testimonials {
    background-image:url('../img/icons/pipes.png'); 
    background-repeat: repeat;
    padding: 3em;
    background-color: $color-brand-light-pink;
    box-shadow: inset 0px 0px 10px rgba(0,0,0,.5);
    * {
        background: none;
    }
    &__quote {
        font-size: 2em;
        font-weight:300;
        font-family: $serif;
        background: none;
    }
    &__name {
        text-align: right;
        font-size:1.5em;
        background: none;
    }
}