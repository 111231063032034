.portfolio {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    padding-bottom: 24px;
    width: 50%;
    margin: 0 auto;
    display: block;
    text-align: center;
    margin-bottom: 24px;
    @media(max-width:767px){
        width: 100%;
    }
}
.portfolio__image {
    background: grey;
    width: 100%;
    min-height: 100px;
}
.portfolio--even {
    flex-direction: row-reverse; 
}
  .portfolio__half {
    padding: 24px;
    &--image {
        flex: 1;
        background-color: #ddc;
        border: solid 5vmin #eee;
        border-bottom-color: #fff;
        border-left-color: #eee;
        border-radius: 2px;
        border-right-color: #eee;
        border-top-color: #ddd;
        box-shadow: 0 0 5px 0 rgba(0,0,0,.25) inset, 0 5px 10px 5px rgba(0,0,0,.25);
        box-sizing: border-box;
        display: inline-block;
        width: 90%;
        padding: 8vmin;
        position: relative;
        text-align: center;
        margin-bottom: 24px;
    }
    &--image:before {
        border-radius: 2px;
        bottom: -2vmin;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
        border-radius: 2px;
        bottom: -2vmin;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.25) inset;
        content: "";
        left: -2vmin;
        position: absolute;
        right: -2vmin;
        top: -2vmin;
    }
    &--image:after {
        border-radius: 2px;
        bottom: -2.5vmin;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
        content: "";
        left: -2.5vmin;
        position: absolute;
        right: -2.5vmin;
        top: -2.5vmin;
    }
  }
  .portfolio__headline {
    font-family: "Sorts Mill Goudy", serif;
    margin-bottom: 16px;
    font-size: 2em;
  }
  .portfolio__date {
    font-weight: 300;
    font-size: 1.25em;
    margin-bottom: 24px;
  }
  .portfolio__preview {
    font-weight: 300;
    font-size: 1.25em;
    margin-bottom: 24px;
  }
  .portfolio__button {
    align-self: flex-start;
    text-decoration: none;
    background: #f1b4bf;
    font-size: 16px;
    padding: 12px 32px;
    border-radius: 28px;
    border: none;
    color: white;
  }