.hero {
    display: flex;
    @media(max-width:767px){
        flex-direction: column;
    }
    &--reverse {
        flex-direction: row-reverse;
        @media(max-width:767px){
            flex-direction:column-reverse;
        }
    }
    *{
        background: none;
    }
    &__image {
        background: grey;
        width: 100%;
    }
    &__textsection{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;    
        background: none;
        padding-right: 16px;
        &--reverse {
            padding-left: 16px;
            padding-right: 0;
        }

    }
    &__imagesection {
        flex: 1;
        background-color: #ddc;
        border: solid 5vmin #eee;
        border-bottom-color: #fff;
        border-left-color: #eee;
        border-radius: 2px;
        border-right-color: #eee;
        border-top-color: #ddd;
        box-shadow: 0 0 5px 0 rgba(0,0,0,.25) inset, 0 5px 10px 5px rgba(0,0,0,.25);
        box-sizing: border-box;
        display: inline-block;
        margin: 5vh 5vw;
        padding: 8vmin;
        position: relative;
        text-align: center;
    }
    &__imagesection:before {
        border-radius: 2px;
        bottom: -2vmin;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
        border-radius: 2px;
        bottom: -2vmin;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.25) inset;
        content: "";
        left: -2vmin;
        position: absolute;
        right: -2vmin;
        top: -2vmin;
    }
    &__imagesection:after {
        border-radius: 2px;
        bottom: -2.5vmin;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
        content: "";
        left: -2.5vmin;
        position: absolute;
        right: -2.5vmin;
        top: -2.5vmin;
    }
    &__headline {
        font-family: $serif;
        margin-bottom: 16px;
    }
    &__subheadline {
        font-family: $sans-serif;
    }
    &__button {
        align-self: flex-start;
        text-decoration: none;
        background: $color-brand-dark-pink;
        font-size: 16px;
        padding: 12px 32px;
        border-radius: 28px;
        border: none;
        color: white;
    }
    &__paragraph {
        font-weight: 300;
        font-size: 1.25em;
        margin-bottom: 24px;
    }
}