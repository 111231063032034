@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Sacramento|Sorts+Mill+Goudy:400,400i');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $sans-serif;
    background: $color-white;
    font-weight: 300;
}
.outerwrapper {
    width:100%;
    margin: 0 auto;
}
.wrapper {
    max-width:1200px;
    padding:16px;
    width:100%;
    margin: 0 auto;
}
.flex {
    display: flex;
    flex-wrap: wrap;
}

.featherlight .featherlight-close-icon {
    background: white;
    width: 42px;
    height: 42px;
    border: 3px solid black;
    border-radius: 24px;
    margin-top: 12px;
    margin-right: 12px;
    font-weight: 800;
    font-size: 18px;
}
.featherlight-next{
    right: 25px;
    left: auto;
    width: 60px;
    @media screen and (max-width:1024px){
        right: 10px;
    }
}
.featherlight-previous{
    left: 25px;
    right: auto;
    width: 60px;
    @media screen and (max-width:1024px){
        left: 10px;
    }
}
.featherlight-next, .featherlight-previous {
    transition: .5s;
    span {
        transition: .5s;
        background: none;
        font-size: 60px;
        font-weight: 500;
    }
}

