.header {
    width: 100%;
    padding: 0px 16px;
    &--reverse {
        flex-direction: row-reverse;
    }
    &__container{
        display: flex;
        flex-direction:row;
        position: relative;
    }
    &__leftside {
        width:270px;
    }
    &__rightside {
        width:calc(100% - 270px);
        display: flex;
        flex-direction: column;
        @media(max-width:767px){
            justify-content: center;
            align-items: flex-end;        
        }
    }
    &__hamburger {
        display: none;
        @media(max-width:767px){
            cursor: pointer;
            display: flex;
            width: 42px;
            height: 42px;
            justify-content: center;
            align-items: center;      
            .svg-inline--fa {
                width: 100%;
                height: 100%;
                padding: 10px;
            } 
        }
    }
    &__mobilerightside {
        display: none;
        * {
            background: none;
            color: white;
        }
        @media(max-width:767px){
            display: flex;
            padding: 24px;
            z-index: 1;
            position: fixed;
            right: -340px;
            top: 0;
            width: 320px;
            background: $color-brand-dark-pink;
            height: 100vh;
            transition: .5s;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            &--active {
                transform: translateX(-324px);
            }
        }

    }
    &__mobilenavitem {
        margin-bottom: 1em;
    }
    &__mobilenavlink {
        font-weight: 600;
        font-size: 1.25em;
        text-decoration: none;
    }
    &__mobileclose {
        border: none;
        position: absolute;
        right: 30px;
        top: 10px;
        font-size: 24px;
        height: 42px;
        width: 42px;
        padding: 0;
        cursor: pointer;
    }
    &__nav {
        display: flex;
        flex-direction:row;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        width: 100%;
        @media(max-width:767px){
            display: none;
        }
    }
    &__utilitynav {
        text-align: right;
        padding-top: 12px;
        padding-bottom: 12px;
        @media(max-width:767px){
            display: none;
        }
    }
    &__utilitylink {
        color: $color-brand-dark-pink;
        font-weight: 500;
    }
    &__logocontainer {
        display: flex;
        flex-direction: row;
        text-decoration: none;
        color: inherit;
        padding-top: 16px;
        padding-bottom: 16px;
    }
    &__logoimage {
        width:100px;
        height:auto;
        align-self:flex-start;
    }
    &__logotext {
        display: flex;
        flex-direction: column;
    }
    &__logotextitem {
        display: flex;
        flex-direction:row;
        font-family: $sans-serif;
        font-size:24px;
        font-weight: 700;
        &--small {
            font-size:16px;
            font-weight:300;
        }
        &--accent {
            color: $color-brand-dark-pink; 
            font-family: $script; 
            padding-left: 2px;
            font-size: 20px;        
        }
    }
    &__navitem {
        list-style: none;
    }
    &__navlink {
        color: $color-black;
        text-decoration: none;
    }

}