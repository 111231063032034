// Global Variables
$color-brand-purple: #bcb3c6;
$color-brand-light-grey: #f2f2f2;
$color-brand-light-pink: #f2dce0;
$color-brand-dark-grey: #d9d9d9;
$color-brand-dark-pink: #f1b4bf;

$color-white: #fcfcfa;
$color-grey-02: #f7f9f9;
$color-grey-15: #d9d9d9;
$color-grey-35: #a5a5a5;
$color-gry-50: #808080;
$color-black: #333333;

$serif: 'Sorts Mill Goudy', serif;
$sans-serif: 'Montserrat', 'Arial', sans-serif; 
$script: 'Sacramento', cursive;