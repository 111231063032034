.footer {
    width: 100%;
    &__container{
        display: flex;
        flex-direction:row;
    }
    &__leftside {
        width:50%;
        @media(max-width:767px){
            display: none;
        }
    }
    &__rightside {
        @media(max-width:767px){
            width: 100%;
        }
        width:50%;
        display: flex;
        flex-direction: column;
    }
    &__nav {
        display: flex;
        flex-direction:row;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        width: 100%;
        @media(max-width:767px){
            justify-content: flex-end;
        }
    }
    &__utilitynav {
        text-align: right;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    &__utilitylink {
        color: $color-brand-dark-pink;
        font-weight: 500;
    }
    &__logocontainer {
        display: flex;
        flex-direction: row;
        text-decoration: none;
        color: inherit;
        padding-top: 16px;
        padding-bottom: 16px;
    }
    &__logoimage {
        width:100px;
        height:auto;
        align-self:flex-start;
    }
    &__logotext {
        display: flex;
        flex-direction: column;
    }
    &__logotextitem {
        display: flex;
        flex-direction:row;
        font-family: $sans-serif;
        font-size:24px;
        font-weight: 700;
        &--small {
            font-size:16px;
            font-weight:300;
        }
        &--accent {
            color: $color-brand-dark-pink; 
            font-family: $script; 
            padding-left: 2px;
            font-size: 20px;        
        }
    }
    &__navitem {
        list-style: none;
    }
    &__navlink {
        color: $color-black;
        text-decoration: none;
    }
    &__icon {
        @media(max-width:767px){
            padding: 0px 16px;
        }
    }
    &__icon a {
        width: 42px;
        height: 42px;
        background: $color-brand-dark-pink;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        * {
            background: none;
        }
        img {
            width: 100%;
        }
    }

}
