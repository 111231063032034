/* Boilerplate */
@import '_variables.scss';
@import '_global.scss';
@import '_font-awesome.scss';

@import '_head.scss';
@import '_hero.scss';
@import '_blogpreview.scss';
@import '_blog-body.scss';
@import '_interiorhero.scss';
@import '_portfolio.scss';
@import '_testimonials.scss';
@import '_center-title.scss';
@import '_image-tiles.scss';
@import '_split-image-text.scss';
@import '_social.scss';
@import '_formsection.scss';
@import '_footer.scss';