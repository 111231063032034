.image-tiles {
    display: flex; 
    flex-wrap: wrap;
    justify-content: space-between;
    &__tile {
        align-self: flex-start;
        margin-bottom: 16px;
        &--full {
            width: 100%
        }
        &--half {
            width: 49%;
        }
        &--third {
            width: 32%;
        }
    }
}