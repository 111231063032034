.centertitle {
    text-align: center;
}
.centertitle__title {
    display: block;
    font-size: 2em;
    font-family: "Sorts Mill Goudy", serif;
    margin-bottom: 16px;
}
.centertitle__subtitle{

}